<script lang="ts" setup>
const props = defineProps<{
  text: string,
}>();

const toast = useToast();
const copied = ref(false);

function copyToClipboard() {
  copied.value = true;
  navigator.clipboard.writeText(props.text);

  toast.add({
    title: 'Copied to clipboard',
    timeout: 2000,
    icon: "i-heroicons-check-circle"
  });

  setTimeout(() => { copied.value = false; }, 2000);
}
</script>

<template>

  <UButton
    :icon="copied ? 'i-heroicons-clipboard-document-check' : 'i-heroicons-clipboard-document'"
    color="gray"
    :padded="false"
    size="xs"
    variant="ghost"
    aria-label="Copy"
    @click.stop="copyToClipboard" />

</template>

<style>
</style>
